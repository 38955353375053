import React, { useState, useEffect, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { AbilityContext, Can } from 'tools/ability';
import Rest from 'tools/rest';
import {
  Button,
  Typography,
  Form,
  Input,
  Modal,
} from 'antd';
import {
  find as _find,
  forEach as _forEach,
  map as _map,
  includes as _includes,
  findIndex as _findIndex,
  reject as _reject,
  isEqual as _isEqual,
} from 'lodash';
import { toast } from 'react-toastify';
import { parseISO as _parseISO, format } from 'date-fns';
import dayjs from 'dayjs';

const { Text } = Typography;
const { TextArea } = Input;

const SubscriberForm = (props) => {
  const [subscriberParams, setSubscriberParams] = useState({
    firstName: null,
    secondName: null,
    lastName: null,
    phone: null,
    owner: false,
  });
  const [errors, setErrors] = useState({})

  const closeModal = () => {
    props.onClose()
  };

  const saveSubscriber = () => {
    const params = {
      first_name: subscriberParams.firstName,
      second_name: subscriberParams.secondName,
      last_name: subscriberParams.lastName,
      phone: subscriberParams.phone,
      owner: subscriberParams.owner,
    }
    Rest.post(`/api/teledom/agreements/add_subscriber.json`, params).then(
      (response) => {
        const { dom } = response.data
        toast.success('Подписчик');
        closeModal();
      }).catch((e) => {
        console.error('error', e);
        if (e.response.data?.errors["phone"]) {
          setErrors({ ...errors, phone: [e.response.data.errors.phone] })
        }
        toast.error('Ошибка добавления подписчика');
      })
  };
  return (
    <Modal
        title='Новый подписчик'
        open={props.showSubscriberForm}
        onCancel={closeModal}
        onOk={saveSubscriber}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            label="Фамилия"
            help={errors?.first_name}
            validateStatus={errors?.key && "error"}
          >
            <Input
              value={keyParams.rfId}
              onChange={(e) => {
                setKeyParams({ ...keyParams, rfId: e.target.value })
                setErrors({})
              }}
            />
          </Form.Item>
          <Form.Item
            label="Имя"
            help={errors?.key}
            validateStatus={errors?.key && "error"}
          >
            <Input
              value={keyParams.rfId}
              onChange={(e) => {
                setKeyParams({ ...keyParams, rfId: e.target.value })
                setErrors({})
              }}
            />
          </Form.Item>
          <Form.Item
            label="Отчество"
            help={errors?.key}
            validateStatus={errors?.key && "error"}
          >
            <Input
              value={keyParams.rfId}
              onChange={(e) => {
                setKeyParams({ ...keyParams, rfId: e.target.value })
                setErrors({})
              }}
            />
          </Form.Item>
          <Form.Item
            label="Комментарий"
            help={errors?.comments}
            validateStatus={errors?.comments && "error"}
          >
            <TextArea
              rows={2}
              value={keyParams.comments}
              onChange={(e) => {
                setKeyParams({ ...keyParams, comments: e.target.value })
                setErrors({})
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
  );
};

export default SubscriberForm
